import { useEffect } from 'react';
import logo from './everloop-new-logo.png';
import './App.css';

import { AiFillLinkedin, AiFillInstagram, AiFillGithub, AiFillMail } from 'react-icons/ai'
import { IoLogoWhatsapp } from 'react-icons/io'

function App() {
  useEffect(() => {
    document.title = 'Everloop';
  
  }, [])
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <span> <b>Coming Soon!</b></span>
        <div className='links'>
          <a href='https://www.instagram.com/dandrevielma' target='_blank'>
            <AiFillInstagram />
          </a>
          <a href='https://www.linkedin.com/in/dandrevielma' target='_blank'>
            <AiFillLinkedin />
          </a>
          <a href='https://wa.me/573244142883' target='_blank'>
            <IoLogoWhatsapp />
          </a>
          <a href='https://github.com/dandrevielma' target='_blank'>
            <AiFillGithub />
          </a>
          <a href='mailto:dandrevielma@gmail.com' target='_blank'>
            <AiFillMail />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
